import PropTypes from "prop-types";
import React from "react";
import HamburgerMenu from "./HamburgerMenu";

const CityHeader = ({ page, ...props }) => {
  return (
    <>
      <HamburgerMenu />
      <div id={page.frontmatter.slug}>
        <section className="hero" id={page.frontmatter.city}>
          <nav role="navigation" id="nav-dropdown">
            <ul>
              <li class="button-outlined">
                <a href="#">Tour Locations</a>
                <ul class="dropdown">
                  <li>
                    <a href="https://billygraham.org/story/a-weight-lifted-idahoans-start-new-life-in-jesus-christ/">Idaho Falls</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div class="hero-header">
            <div class="desktop-menu">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href={"/" + page.frontmatter.city}>
                    {page.frontmatter.cityNav}
                  </a>
                </li>
                <li>
                  <a href={"/" + page.frontmatter.city + "/espanol/"}>
                    ESPAÑOL
                  </a>
                </li>
                <li>
                  <a href={"/" + page.frontmatter.city + "/events/"}>Events</a>
                </li>
                <li>
                  <a href={"/" + page.frontmatter.city + "/clwc/"}>
                    Christian Life & Witness
                  </a>
                </li>
                <li>
                  <a href={"/" + page.frontmatter.city + "/resources/"}>
                    Resources
                  </a>
                </li>
                <li>
                  <a href={page.frontmatter.giveLink}>Give</a>
                </li>
              </ul>
            </div>
            <a href={"/" + page.frontmatter.city}>
              <img
                src={page.frontmatter.logo}
                alt=""
                style={{ height: "250px", width: "300px" }}
              />
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

CityHeader.propTypes = {
  page: PropTypes.any,
};

export default CityHeader;
