import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import SiteFooter from '../components/SiteFooter';
import CityHeader from '../components/CityHeader';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function cityIndex({ pageContext }) {
  const page = pageContext;
  return (
    <Layout>
      <div>
        <CityHeader page={page} />
        <div className="home-content">
          <div className={page.frontmatter.city}>
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </div>
        <SiteFooter />
      </div>
    </Layout>
  );
}

export default cityIndex;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        pageTitle
        ogImage
        metaDesc
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title, ogImage, metaDesc },
    },
  },
}) => {
  return <SEO ogImage={ogImage} title={title} description={metaDesc} />;
};
